








  import { Component, Prop, Vue } from 'vue-property-decorator'

  import isMobile                                      from 'is-mobile'
  import { URL_EKKO_REGISTER, URL_EKKO_DOWNLOAD_BASE } from '@/constants'
  import { EkkoLandingAnalytics }                      from '@/ekko-landing.analytics'

  @Component( {
    name      : 'ekko-button-download',
    components: {}
  } )
  export default class EkkoButtonDownload extends Vue {
    @Prop() isDark!: boolean
	@Prop() textMobile!: string

	protected get isMobile(){
      return isMobile({featureDetect:true, tablet:true})
	}

	protected get link(){
      if (this.isMobile){
        return URL_EKKO_REGISTER
	  } else {
        return URL_EKKO_DOWNLOAD_BASE + '&' + EkkoLandingAnalytics.getCampaignQueryForOverwolf()
	  }
	}

	protected onClick(){
      if(this.isMobile){
        EkkoLandingAnalytics.sendRegisterLink()
	  } else {
        EkkoLandingAnalytics.sendDownload()
	  }
	}
  }

