export function getImageSet (paths) {
  const imagePaths = paths.split(',').map(path => path.trim())

  const images = imagePaths
  // -webkit-image-set supports [number]x and [number]dpi notation. So we only need the first few here (1x and 2x)
  .slice(0, 2)
  .map((path) => {
    const parts = path.split(" ")
    return `url(${parts[0]}) ${parts[1]}`
  })
  .join(', ')

  const isFirefox = navigator.userAgent.indexOf("Firefox") >= 0

  return isFirefox 
    ? `background-image: image-set(${images})`  // Firefox
    : `background-image: -webkit-image-set(${images})` // Chrome, Safari, and others
}
