






























import { Component, Vue }  from 'vue-property-decorator'
import { influencers }     from '@/constants'
import { IEkkoInfluencer } from '@/types'
import { getImageSet } from '@/functions'
import EkkoButtonDownload  from '@/components/ekko.button-download.vue'

@Component({
  name: 'ekko-influencer-card',
  components: { EkkoButtonDownload },
})
export default class EkkoInfluencerCard extends Vue {
  protected influencer: IEkkoInfluencer | null = null
  protected getImageSet = getImageSet

  protected get avatar() {
    return require(`@/assets/img/influencers/avatar_${ this.influencerUrl }.png`)
  }

  protected get name() {
    return this.influencer?.name
  }

  protected get influencerUrl() {
    return this.$route.meta.influencer
  }

  created() {
    this.influencer = influencers.find((i: IEkkoInfluencer) => {
      return i.urlFragment === this.influencerUrl
    }) || null

    if (!this.influencer) {
      this.$router.push({ path: '/' })
    }
  }
}
