

































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ekko-terms-of-service',
  components: {  },
})
export default class EkkoTermsOfService extends Vue {

}
