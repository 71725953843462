




























import { Component, Vue } from 'vue-property-decorator'
import { anchors }        from '@/constants'
import { IEkkoAnchor }    from '@/types'
import EkkoButtonDownload from '@/components/ekko.button-download.vue'

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
// function elementInViewport(el) {
//   if (!el) {
//     return false
//   }

//   const height = el.offsetHeight
//   const top = el.offsetTop
//   const windowTop = window.pageYOffset + 170
//   // const windowHeight = window.innerHeight

//   return (
//     top <= windowTop
//     && (top + height) >= (windowTop)
//   )
// }

@Component({
  name: 'ekko-header-nav',
  components: { EkkoButtonDownload },
})
export default class EkkoHeaderNav extends Vue {
  // protected blocks: any[] = []
  protected anchors!: IEkkoAnchor[]
  protected isUserScrolling = false
  protected activeBlock: any = null
  protected influencerUrl = null

  protected get baseUrl() {
    return this.influencerUrl ? `/${ this.influencerUrl }` : ''
  }

  created() {
    document.addEventListener('scroll', this.handleScroll)
    this.influencerUrl = this.$route.meta.influencer
  }

  beforeCreate() {
    this.anchors = anchors
  }

  mounted() {
    // this.blocks = anchors.map((anchor: IEkkoAnchor) => {
    //   return document.getElementById(anchor.id)
    // })

    this.handleScroll()
  }

  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    this.isUserScrolling = (window.scrollY > 0)

    // Detect scroll position and update url
    // TODO: commented out because there is no known way to update url silently without triggering scrollBehavior.
    // this.blocks.find(el => {
    //   if(elementInViewport(el)) {
    //     if (this.activeBlock !== el) {
    //       this.activeBlock = el

    //       // const hash = `#${ el.id }`
    //       // if (this.$route.hash !== hash) {
    //       //     this.$router.push({ hash, params: { doNotScroll: 'true' } })
    //       // }
    //     }
    //     return true
    //   }
    // })
  }
}
