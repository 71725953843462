




























import { Component, Vue } from 'vue-property-decorator'
import { anchors } from '@/constants'
import { IEkkoAnchor } from '@/types'

@Component({
  name: 'ekko-footer',
  components: { },
})
export default class EkkoFooter extends Vue {
  protected anchors!: IEkkoAnchor[]
  protected influencerUrl = null

  protected get baseUrl() {
    return this.influencerUrl ? `/${ this.influencerUrl }` : ''
  }

  created() {
    this.influencerUrl = this.$route.meta.influencer
  }

  beforeCreate() {
    this.anchors = anchors
  }
}
