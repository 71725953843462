























import { Component, Vue } from 'vue-property-decorator'
import { faq } from '@/constants'

@Component({
  name: 'ekko-faq',
  components: { },
})
export default class EkkoFaq extends Vue {
  protected faq

  beforeCreate() {
    this.faq = faq
  }
}
