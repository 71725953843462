declare const gtag: (...args:any)=>void

const ANALYTICS_CAMPAIGN_MEDIUM = 'landing_page'
const ANALYTICS_CAMPAIGN_NAME   = 'influencer_campaign_launch'
const ANALYTICS_CAMPAIGN_SOURCE_DEFAULT                      = 'direct'
const ANALYTICS_PAGE_TITLE = 'Ekko Landing Page June 2021'

/**
 * using global tag, see index.html head
 */
export class EkkoLandingAnalytics {
  static MEASUREMENT_ID= 'UA-182952899-6'

  static campaign = {} as {name, medium, source}

  static setCampaign(campaign){
    gtag('set', { campaign })
  }

  static setCampaignSource(source:string){
    this.campaign.source = source
    this.setCampaign(this.campaign)
  }

  static sendPageView(params: {title?, location?, path?}){
    gtag('event', 'page_view', {
      page_title: params.title,
      page_location: params.location,
      page_path: params.path
    })
}

/** sets up datalayer and helper function but does not config the gtag yet.
 * That's done once the App is mounted (see App.vue) to ensure we have the initial routing considered.
 * That routing might contain the influencer name we need as campaign_source.
 * */
  public static init() {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.gtag = function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

  this.campaign                   = {
      medium: ANALYTICS_CAMPAIGN_MEDIUM,
      name: ANALYTICS_CAMPAIGN_NAME,
      source: ANALYTICS_CAMPAIGN_SOURCE_DEFAULT
    }

    this.setCampaign(this.campaign)
}

  public static initGtagConfig(){
    gtag('config', this.MEASUREMENT_ID, {
      page_title: ANALYTICS_PAGE_TITLE
    });
  }

  public static getCampaignQueryForOverwolf() {
    return `utm_campaign=${this.campaign.name}&utm_source=${this.campaign.source}&utm_medium=${this.campaign.medium}`
  }

  public static sendDownload() {
    gtag('event', 'download');
  }

  public static sendRegisterLink() {
    gtag('event', 'redirect-to-website');
  }
}
