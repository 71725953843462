import { IEkkoAnchor, IEkkoFaq, IEkkoInfluencer } from "./types";

export const URL_EKKO_REGISTER = "https://ekko.gg/"
export const URL_EKKO_DOWNLOAD_BASE = "https://download.overwolf.com/install/Download?Name=Ekko&ExtensionId=pjoamhejahmldfaigdhifobjbohhabdpodnlbeae&Channel=website"

export const anchors: IEkkoAnchor[] = [
  { label: 'Why Ekko', id:"why-ekko" },
  { label: 'Gaming Clips', id:"gaming-clips" },
  { label: 'Editor', id:"editor" },
  { label: 'Storage', id:"storage" },
  { label: 'Gaming Profile', id:"gaming-profile" },
  { label: 'Get Started', id:"get-started" },
]

export const gameLogos =  [
  require('@/assets/img/games/logo/icon-game-7314.png'),
  require('@/assets/img/games/logo/icon-game-7764.png'),
  require('@/assets/img/games/logo/icon-game-10624.png'),
  require('@/assets/img/games/logo/icon-game-5426.png'),
  require('@/assets/img/games/logo/icon-game-10798.png'),
  require('@/assets/img/games/logo/icon-game-10906.png'),
  require('@/assets/img/games/logo/icon-game-21216.png'),
  require('@/assets/img/games/logo/icon-game-21570.png'),
  require('@/assets/img/games/logo/icon-game-21640.png'),
  require('@/assets/img/games/logo/icon-game-6365.png'),
  require('@/assets/img/games/logo/icon-game-10746.png'),
  require('@/assets/img/games/logo/icon-game-10826.png'),
  require('@/assets/img/games/logo/icon-game-21566.png'),
]

export const influencers: IEkkoInfluencer[] = [
  {
    name       : 'IreliaCarriesU',
    urlFragment: 'ireliacarriesu',
    avatar     : 'avatar_ireliacarriesu.png'
  },
  {
    name       : 'souless',
    urlFragment: 'souless',
    avatar     : 'avatar_souless.png'
  },
  {
    name       : 'MaTaFe_',
    urlFragment: 'matafe_',
    avatar     : 'avatar_matafe_.png'
  },
  {
    name       : 'mero',
    urlFragment: 'mero',
    avatar     : 'avatar_mero.png'
  },
  {
    name       : 'Calculator',
    urlFragment: 'calculator',
    avatar     : 'avatar_calculator.png'
  },
  {
    name       : 'Rocket Sledge',
    urlFragment: 'rocketsledge',
    avatar     : 'avatar_rocketsledge.png'
  },
]

export const faq: IEkkoFaq[] = [
  {
    question: `
    How do I send feedback or bug reports?
    `,
    answer: `
    At the top of the Ekko app there is a blue button titled “Feedback.” Click this button to send us an idea that you want the developers to hear, a bug that needs to be fixed, a question for the Ekko team, or general feedback about your experience with Ekko.
    `
  },
  {
    question: `
    What is the Upload Queue?
    `,
    answer: `
    The Upload Queue is where you can check the status of your posts. It will give you details on whether a video is still uploading, is done uploading, failed to upload, or if it was canceled. 
    `
  },
  {
    question: `
    What is the Highlights page?
    `,
    answer: `
    The Highlights page is your main feed where you can see all of the latest videos in the community, as well as the highlights you and your friends post. 
    `
  },
  {
    question: `
    What is the Explore page? 
    `,
    answer: `
    The Explore page allows you to browse highlights by category, whether you want to look at them for one particular game or if you only want to see what’s popular and trending.
    `
  },
  {
    question: `
    What is the Communities page?
    `,
    answer: `
    The Communities page is your social hub for connecting with other gamers and sharing clips with people that have the same gaming interests as you.
    `
  },
  {
    question: `
    What is the Local Clips page?
    `,
    answer: `
    The Local Clips page is where Ekko stores your captured clips to your local drive. Here you can edit, publish, and delete any of your raw captures. 
    `
  },
  {
    question: `
    What does “Create Montage” do?
    `,
    answer: `
    Creating a montage will bring you into the editor, allowing you to edit your clips. It will then combine all of your selected highlights into one seamless video making it montage-ready once you click publish.
    `
  },
  {
    question: `
    What does “Create Playlist” do?
    `,
    answer: `
    Creating a playlist will bring you into the editor, allowing you to edit your selected clips and then publish them as a playlist, which will allow you to view all the published clips separately, but as a part of the same single post. 
    `
  },
  {
    question: `
    How do I view/edit my profile? 
    `,
    answer: `
    In the top right area of the Ekko app to the right of the notification bell is your profile picture. Clicking on it will open a menu box that allows you to manage numerous options and view/edit your profile.
    `
  },
  {
    question: `
    How do I manage notifications, friends, followers, and games on Ekko?
    `,
    answer: `
    By clicking on your profile icon located on the top right of the Ekko app to the right of the notification bell, you will be able to manage all of these settings from there. 
    `
  },
  {
    question: `
    How do I change recording settings on Ekko?
    `,
    answer: `
    At the top right next to the Local Clips page will be a page called “Recording Settings.” Clicking on that will take you to the recording settings page where you will be able to change the general settings for Ekko or the specific settings for each individual game. 
    `
  },
]
