


























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { gameLogos }      from '@/constants'
import { getImageSet } from '@/functions'
import EkkoViewableImage  from '@/components/ekko.viewable-image.vue'
import EkkoButtonDownload from '@/components/ekko.button-download.vue'

@Component({
  name: 'ekko-home',
  components: { EkkoButtonDownload, EkkoViewableImage },
})
export default class EkkoHome extends Vue {
  protected logos
  protected isImageModalActive = false
  protected modalImage = ""
  protected getImageSet = getImageSet

  viewImage(images: string) {
    this.modalImage = images.split(',').reverse()[0] // chose highest resolution
    this.isImageModalActive = true
  }

  beforeCreate() {
    this.logos = gameLogos
  }
}
