import 'buefy/dist/buefy.css'
import '@/assets/css/global.scss'

import Buefy                    from 'buefy'
import VueRouter                from 'vue-router'
import Vue                      from 'vue'
import App                      from './App.vue'
import { routes }               from './routes'
import { EkkoLandingAnalytics } from '@/ekko-landing.analytics'

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  // defaultContainerElement: '#content',
  defaultNotificationPosition: 'is-top',
  defaultNotificationDuration: 10000,
  defaultNoticeQueue: false,
})

const router = new VueRouter({
  mode: 'history',
  // https://stackoverflow.com/a/64654535/3288102
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      const offset = to.hash === '#get-started' ? 0 : 40 //px
      const headerHeight = 96  //px
      return {
        selector: to.hash,
        offset: { x: 0, y: headerHeight + offset},
        behavior: 'smooth',
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

EkkoLandingAnalytics.init()

Vue.use(VueRouter)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
